.is-alternate .page-navigation__hotswap {
  padding-top: 30px;
}

.page-navigation__hotswap .depth-2 .menu {
  max-width: 250px;
}

.menu-item-container--childcount-3 {
  max-width: 34.33333%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .best-sellers-elc-mpp .mpp .product_brief__description {
    top: -15px;
  }
  .best-sellers-elc-mpp .mpp .product_brief__desc1-reviews {
    height: 47px;
  }
}

.brand-renutriv .mpp .product-brief__image {
  background: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% top;
}

.country-chooser a.country-chooser__selector {
  width: 200px;
}
/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li {
    padding-right: 25px !important;
  }
}
/* footer css for ipad landscape */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  .page-navigation .level-3 {
    margin: 5px 0 !important;
  }
}
/* font style */
@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.product_brief__button--add-to-bag,
.product_brief__sub-panel-buttons-container {
  width: 190px !important;
}
/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li.last {
    margin-top: -57px !important;
    margin-right: 150px !important;
  }
}

.header__headline,
.mpp__header,
.headline--tertiary {
  font-family: 'Optima Display Roman' !important;
}

.brand-renutriv {
  .mpp__header,
  .header__headline,
  .headline--tertiary {
    font-family: $bb-roman !important;
  }
}

.page-utilities__signup .error {
  word-wrap: break-word;
  width: 250px;
}

.welcome15-overlay.alt_layout1 .email_popover .email_popover__content {
  width: 430px;
}
